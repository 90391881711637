.p-component {
    margin: 0;
    font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, 'Work Sans', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: normal;
}

span.p-calendar {
    width: 100%;
}

.p-calendar {
    position: relative;
    display: inline-flex;
}

.p-inputtext{
  //  min-width: 17rem;
}

.p-calendar .p-inputtext {
    flex: 1 1 auto;
    width: 1%;
}

.p-calendar-w-btn .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-calendar-w-btn .p-datepicker-trigger {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* Fluid */
.p-fluid .p-calendar {
    display: flex;
}

.p-fluid .p-calendar .p-inputtext {
    width: 1%;
}

/* Datepicker */
.p-calendar .p-datepicker {
    min-width: 100%;
}

.p-datepicker {
    width: auto;
    position: absolute;
}

.p-datepicker-inline {
    display: inline-flex;
    position: static;
}

/* Header */
.p-datepicker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-datepicker-header .p-datepicker-title {
    margin: 0 auto;
}

.p-datepicker-prev,
.p-datepicker-next {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
    display: flex;
}

/* DatePicker Table */
.p-datepicker table {
    width: 100%;
    border-collapse: collapse;
}

.p-datepicker td > span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

/* Month Picker */
.p-monthpicker-month {
    width: 33.3%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

/*  Button Bar */
.p-datepicker-buttonbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Time Picker */
.p-timepicker {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-timepicker button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.p-timepicker > div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 80vw;
    transform: translate(-50%, -50%);
}

/* Table starts */

.p-paginator .p-paginator-current {
    margin-left: auto;
}

.p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;
}

.p-progressbar .p-progressbar-value {
    background-color: #607d8b;
}

.table-header {
    display: flex;
    justify-content: end;
}

.p-calendar .p-datepicker {
    min-width: 25rem;
}

.p-calendar .p-datepicker td {
    font-weight: 400;
}

.p-paginator .p-paginator-first {
    border-left: 1px solid #D2D2D2;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
    border-right: 0;
}

.p-paginator .p-paginator-prev {
    border-left: 0;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
    border-right: 0;
}

.p-paginator .p-paginator-next {
    border-left: 0;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
    border-right: 0;
}

.p-paginator .p-paginator-last {
    border-left: 0;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
    border-right: 1px solid #D2D2D2;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    border-left: 0;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
    border-right: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-color: #D2D2D2;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #5055CB;
    margin: 0;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #5055CB;
    margin: 0;
}

.p-datatable.p-datatable-employee .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.p-datatable.p-datatable-employee .p-paginator {
    padding: 1rem;
}

.p-datatable.p-datatable-employee .p-paginator .p-paginator-current {
    color: #6c757d;
}

.p-datatable.p-datatable-employee .p-paginator .p-paginator-first, .p-datatable.p-datatable-employee .p-paginator .p-paginator-prev, :host ::ng-deep .p-datatable.p-datatable-employee .p-paginator .p-paginator-next, :host ::ng-deep .p-datatable.p-datatable-employee .p-paginator .p-paginator-last {
    color: #6c757d;
}

/* .p-datatable.p-datatable-employee .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	 background: #6c757d;
	 border-color: #6c757d;
	 color: #fff;
} */

.p-datatable.p-datatable-employee .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #fff;
    color: #5055CB;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    color: #6c757d;
}

.p-datatable.p-datatable-employee .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #6c757d;
    color: #fff;
}

.p-datatable.p-datatable-employee .p-datatable-thead > tr > th {
    font: normal normal 600 16px/22px Nunito Sans;
    letter-spacing: 0;
    color: #4D4D4D;
    opacity: 1;
}

.p-datatable.p-datatable-employee .p-datatable-tbody > tr > td {
    cursor: auto;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 19px;
    padding: 5px;
}

.p-datatable.p-datatable-employee .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.p-datatable-employee .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.p-datatable-employee .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}


/* Datatable dashboard starts */
.p-datatable.p-datatable-dashboard .p-datatable-header {
    background: #ddd;
    padding: 0.4rem 0.4rem;
    font-weight: 500;
    text-align: left;
    font-size: 1rem;
}

.p-datatable.p-datatable-dashboard .p-datatable-thead > tr > th {
    text-align: center;
    padding: 0.571rem 0.571rem;
    border-width: 0 0 2px 0;
    font-weight: 500;
    background: transparent;
    transition: box-shadow 0.2s;
}

.p-datatable.p-datatable-dashboard .p-paginator {
    padding: 1rem;
}

.p-datatable.p-datatable-dashboard .p-paginator .p-paginator-current {
    color: #6c757d;
}

.p-datatable.p-datatable-dashboard .p-paginator .p-paginator-first, .p-datatable.p-datatable-dashboard .p-paginator .p-paginator-prev, :host ::ng-deep .p-datatable.p-datatable-dashboard .p-paginator .p-paginator-next, :host ::ng-deep .p-datatable.p-datatable-dashboard .p-paginator .p-paginator-last {
    color: #6c757d;
}

.p-datatable.p-datatable-dashboard .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #6c757d;
    /* border-color: #6c757d; */
    color: #fff;
}

.p-datatable.p-datatable-dashboard .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #6c757d;
    color: #fff;
}

.p-datatable.p-datatable-dashboard .p-datatable-thead > tr > th {
    text-align: center;
}

.p-datatable.p-datatable-dashboard .p-datatable-tbody > tr > td {
    cursor: auto;
}

.p-datatable.p-datatable-dashboard .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.p-datatable-dashboard .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.p-datatable-dashboard .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-tooltip {
   border-radius: 8px;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: white;
    box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important
}
.p-tooltip .p-tooltip-text {
    background-color: white !important;
    color: #4D4D4D;
}

.p-panel {
    background: white !important;
    border-radius: 8px;
    margin-bottom:  20px;
}

.p-panel .p-panel-content {
    color: #212529;
    padding: 0;
    margin-bottom: 16px;
}

.panel-wrapper {
    padding: 28px;
    margin-bottom: 16px;
}
.header-border-fix {
    position: relative;
    top: -5px;
    height: 8px;
    width: 100%;
    background-color: white;
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: 28px;
}

.p-panel .p-panel-header {
    background: white;
    color: #212529;
    padding: 28px;
    border-radius: 8px;
}

.pi-angle-up:before {
    font-size: 28px;
    color: #6D6D6D
}

.pi-angle-down:before {
    font-size: 28px;
    color: #6D6D6D
}

.panel-header {
    display: flex;
    align-items: center;
    background: white;
    &-icon {
        svg {
            height: 40px;
            margin: 18px;
            stroke-width: 1;
        }
    }

    &-title {
        h3 {
            font: normal normal 600 20px/27px Nunito Sans;
            letter-spacing: 0;
            color: #4D4D4D;
            opacity: 1;
            margin: 0;
        }

        span {
            font: normal normal normal 14px/19px Nunito Sans;
            letter-spacing: 0;
            color: #4D4D4D;
            opacity: 1;
        }
    }
}

.panel-header-row {
    margin-top: 25px;

    &-fields {
        h3 {
            font-size: 1rem;
            font-weight: 600;
            color: #4D4D4D;
            margin: 0;
        }

        span {
            font-size: 0.875rem;
            color: #4D4D4D;
        }
    }
}

@media screen and (max-width: 960px) {
    .p-datatable.p-datatable-employee .p-datatable-thead > tr > th, .p-datatable.p-datatable-employee .p-datatable-tfoot > tr > td {
        display: none !important;
    }
    .p-datatable.p-datatable-employee .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--layer-2);
    }
    .p-datatable.p-datatable-employee .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
        padding: 12px;
    }
    .p-datatable.p-datatable-employee .p-datatable-tbody > tr > td .p-column-title {
        padding: 0.4rem;
        min-width: 30%;
        display: inline-block;
        margin: -0.4rem 1rem -0.4rem -0.4rem;
        font-weight: bold;
    }
    .p-datatable.p-datatable-employee .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: 0.5rem;
    }
}

/* Table ends */

.btn-icon {
    svg {
        width: 24px;
        height: 24px;
        margin-right: 6px;
        margin-left: 6px;
    }
}

.btn-icon-primary {
    svg {
        width: 24px;
        height: 24px;
        color: #5055CB;
        margin-right: 6px;
        margin-left: 6px;
    }
}

.btn-icon-danger {
    svg {
        width: 24px;
        height: 24px;
        color: #D62828;
        margin-right: 6px;
        margin-left: 6px;
    }
}

.btn-icon-success {
    svg {
        width: 24px;
        height: 24px;
        color: #248307;
        margin-right: 6px;
        margin-left: 6px;
    }
}
