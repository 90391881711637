p-inputmask.ng-dirty.ng-invalid >.p-inputtext:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.25);
}

.p-dropdown {
    color: #6e707e;
    background-color: #ECF2F7;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.31rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-right: 22px;
}

.p-dropdown-items {
    font-size: 0.875rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #f8f9fc;
    background: #616AFF;
}

.p-calendar .p-inputtext {
    font-size: 0.875rem;
    color: #6D6D6D;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-radius: 0.31rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.p-calendar .p-inputtext::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 0.875rem;
    color: #6D6D6D;
}
.p-calendar .p-inputtext::-moz-placeholder { /* Firefox 19+ */
    font-size: 0.875rem;
    color: #6D6D6D;
}
.p-calendar .p-inputtext:-ms-input-placeholder { /* IE 10+ */
    font-size: 0.875rem;
    color: #6D6D6D;
}
.p-calendar .p-inputtext:-moz-placeholder { /* Firefox 18- */
    font-size: 0.875rem;
    color: #6D6D6D;
}

.p-inputswitch {
    width: 44px;
    height: 20px;
}

.p-inputswitch .p-inputswitch-slider {
    border-radius: 20px;
    background: #FFF;
    border: 1px solid #D2D2D2;
}

.p-button.p-button-secondary.p-button-text {
    color: #5055CB;
    width: 1.125rem;
    height: 1.125rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
    background: #5055CB;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #ffffff;
}

.p-inputswitch .p-inputswitch-slider::before {
    border-radius: 100%;
    background: #6C7868;
    width: 14px;
    height: 14px;
    margin-top: -7px;
}

.p-button.p-button-sm {
    padding: 5px 20px;
    border-radius: 1.625rem;
}

.p-datatable .p-datatable-header {
    background: transparent;
    border: 0;
    // border-left: 4px solid rgb(125,185,153);
    // border-image-outset: 0;
    // border-image-repeat: stretch;
    // border-image-slice: 100%;
    // border-image-source: none;
    // border-image-width: 1;
    // border-image: linear-gradient(to bottom, rgba(108,219,141,0) 25%,rgb(78, 115, 223) 25%,rgb(78, 115, 223) 75%,rgba(108,219,141,0) 75%);
    // border-image-slice: 100%;
    // border-image-slice: 1;

    .p-input-icon-left {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.p-datatable .p-datatable-thead > tr > th {
   // padding: 1rem 0.5rem;
    border: 0;
}

.p-datatable.p-datatable-employee .p-datatable-tbody > tr > td {
    //padding-top: 23.11px;
    //padding-bottom: 21.89px;
}

.p-datatable .p-datatable-tbody > tr:first-child > td {
    border-top: 0;
}

.p-datatable .p-datatable-header > tr > th {
    border: 0px;
}

.p-inputtext {
    color: #6e707e;
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 0.31rem;
    width: 70%;
    margin-right: 25px;
}

.p-inputtext.inputsearch {
  border-radius: 5px;
}

// Date Picker Header
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ffffff;
    font-size: 1.2rem;
}

.p-datepicker .p-datepicker-header {
    color: #5056CC;
    border-bottom: 0;
}

// Date Picker Table Header
.p-datepicker table th {
    color: #5056CC;
}

.p-calendar .p-datepicker td {
    color: #5056CC;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev {
    color: #5056CC;
}

.p-datepicker .p-datepicker-header .p-datepicker-next {
    color: #5056CC;
}


// Date Picker Tabler Body
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #6E73DE;
    color: #fff;
}

.p-datepicker table td.p-datepicker-today > span {
    background: #5056CC;
    color: #fff;
    border-color: transparent;
}

.p-datepicker table td > span {
    border-radius: 50%;
}

.p-dropdown {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #D4D6D9;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgba(193, 207, 252, 0.5);
    border-color: #d1d3e2;
    background: #ffffff;
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.5rem;
  }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    font-size: 0.875rem;
    padding: 6px 14px;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    font: normal normal normal 14px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #6D6D6D;
    opacity: 1;
    padding: 6px 14px;
  }
  .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #83888F;
    width: 2.357rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: #83888F;
    right: 2.357rem;
  }

  .p-dropdown-panel {
    background: #ffffff;
    color: #69707A;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #69707A;
    background: #FCFCFC;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.5rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.5rem;
    color: #83888F;
  }
  .p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
    padding: 0.5rem 0;
  }
  .p-dropdown-panel .p-dropdown-items.p-dropdown-virtualscroll .cdk-virtual-scroll-content-wrapper {
    padding: 0.5rem 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #69707A;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #FFFFFF;
    background: #3696ff;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #FFFFFF;
    background: #3696ff;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 1rem;
    color: #69707A;
    background: transparent;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #69707A;
    background: #ffffff;
    font-weight: 600;
  }

  p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    border-color: #f44336;
  }

  .p-paginator .p-paginator-current {
      border: 0px;
      font-size: 0.875rem;
  }

/* MODAL */
.p-dialog .p-dialog-header {
    // border-top-right-radius: 8px;
    // border-top-left-radius: 8px;
    border-bottom: 0;
    padding: 2.5rem;
    font: normal normal 600 28px/38px Nunito Sans;
    color: #4D4D4D;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin: 0;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font: normal normal 600 28px/38px Nunito Sans;
    border-left: 0.25rem solid #5056CC !important;
    color: #4D4D4D;
    padding-left: 10.5px;
}

.p-dialog .p-dialog-header-icons {
    button {
        span {
            font-size: 2rem;
        }
    }
}

.p-dialog .p-dialog-content {
    padding: 0 2.5rem;
    // border-bottom-left   -radius: 8px;
    // border-bottom-right-radius: 8px;
    font: normal normal normal 16px/22px Nunito Sans !important;
    color: #4D4D4D !important;
}

.p-dialog .p-dialog-footer {
    border-top: 0;
    padding-bottom: 29px;
    padding-top: 29px;
    font: normal normal normal 16px/22px Nunito Sans !important;
    color: #4D4D4D !important;
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 8px;
    button {
        width: 129px;
        font: normal normal 600 16px/22px Nunito Sans;
    }
}

.p-treeselect {
    width: 100%;
}

.p-treeselect-label-container {
    font-size: 14px;
}

.p-treeselect .p-treeselect-label {
    padding: 0.35rem 0.75rem;
}

.p-avatar img {
    object-fit: cover;
}
