/* nunito-sans-200 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 200;
    src: url('/assets/fonts/nunito-sans-v11-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-200.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-200.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-200italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 200;
    src: url('/assets/fonts/nunito-sans-v11-latin-200italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-200italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-200italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-300 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/nunito-sans-v11-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-300.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-300italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/nunito-sans-v11-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-300italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-regular - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/nunito-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/nunito-sans-v11-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-600 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/nunito-sans-v11-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-600.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-600.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-600italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 600;
    src: url('/assets/fonts/nunito-sans-v11-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-600italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-700 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/nunito-sans-v11-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-700italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/nunito-sans-v11-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-700italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-800 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/nunito-sans-v11-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-800.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-800italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 800;
    src: url('/assets/fonts/nunito-sans-v11-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-800italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-900 - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/nunito-sans-v11-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-900.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-900.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-900italic - latin */
@font-face {
    font-family: 'Nunito Sans';
    font-style: italic;
    font-weight: 900;
    src: url('/assets/fonts/nunito-sans-v11-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/nunito-sans-v11-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/nunito-sans-v11-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/nunito-sans-v11-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/nunito-sans-v11-latin-900italic.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
